<template>
    <div class="box">
        <h2>Parser test</h2>
        <div class="body">
            <product-query-assist v-model="querytext" :product="product" />
        </div>
    </div>
</template>

<script>
import ProductQueryAssist from "../components/widgets/ProductQueryAssist";

export default {
    data() {
        return {
            querytext: "",
            product: "private.mkb"
        };
    },
    components: {
        ProductQueryAssist
    }
};
</script>
